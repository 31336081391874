import $ from "jquery";

import("swiper/scss");

(async () => {
  const { Swiper } = await import("swiper");
  const { Navigation } = await import("swiper/modules");

  $(".product-display--type-2").each(function () {
    const swiper = new Swiper($(this).find(".swiper")[0], {
      modules: [Navigation],
      slidesPerView: 1.125,
      spaceBetween: 20,
      createElements: true,
      navigation: {
        nextEl: $(this).find(".nav--next")[0],
        prevEl: $(this).find(".nav--prev")[0],
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
    });

    // Tabs functionality
    const $tabs = $(this).find(".tab");
    $tabs.click(function () {
      $tabs.removeClass("tab--active");
      $(this).addClass("tab--active");
      const category_id = $(this).data("category-id");
      $(swiper.slides).each(function () {
        $(this)[
          category_id === $(this).data("category-id") ? "show" : "hide"
        ]();
      });
      swiper.update();
    });
  });
})();
